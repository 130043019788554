<template>
  <div class="content">
    <Header />
    <div class="info">
      <div class="bannerList">
        <div class="list">
          <div v-for="(item, key) in bannerList" :key="key" class="item flex">
            <div class="desc">
              <a
                href="#!"
                :data-id="item.id"
                @click.prevent="del"
                class="del"
              ></a>
              <img :src="item.desctop" alt="" />
              <span>{{ item.name }}</span>
            </div>
            <div class="mob">
              <img :src="item.mobile" alt="" />
              <span></span>
            </div>
          </div>
        </div>
        <div class="form">
          <h3>Новый банер</h3>
          <div class="ui flex fWrap fjcS">
            <div class="row col80">
              <span>Текст</span>
              <input type="text" v-model="name" />
            </div>
            <div class="row col20">
              <span>Тег</span>
              <input type="text" v-model="tag" />
            </div>
            <div class="row col100">
              <span>Ссылка</span>
              <input type="text" v-model="link" />
            </div>
            <div class="row col50">
              <span>Картинка desctop </span>
              <input
                type="file"
                id="fileDesctop"
                ref="fileDesctop"
                accept="image/*"
                v-on:change="handleFileUploadDesctop()"
              />
            </div>
            <div class="row col50">
              <span>Картинка mobile </span>
              <input
                type="file"
                id="fileMobile"
                ref="fileMobile"
                accept="image/*"
                v-on:change="handleFileUploadMobile()"
              />
            </div>
            <div class="row col25">
              <a href="#!" class="btn" @click.prevent="BANNERS_add"
                >Сохранить</a
              >
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import api from "@/components/app/api";
import Helper from "@/components/app/helper";
import Header from "@/components/app/header";
export default {
  name: "Home",
  data() {
    return {
      bannerList: [],
      name: "",
      tag: "",
      link: "",
      mobile: "",
      desctop: "",
      file: "",
    };
  },
  watch: {},
  computed: {},
  methods: {
    async del(e) {
      if (confirm("Удаляем баннер?")) {
        let id = e.target.dataset.id;
        let response = await api.methods.BANNERS_del({
          id,
        });

        Helper.methods.warning(response, "ok");
        this.list();
      }
    },
    async list() {
      this.bannerList = await api.methods.BANNERS_list();
    },
    handleFileUploadDesctop() {
      console.log("1");
      this.file = this.$refs.fileDesctop.files[0];
      let reader = new FileReader();
      reader.addEventListener(
        "load",
        function () {
          this.desctop = reader.result;
          console.log(this.desctop);
        }.bind(this),
        false
      );
      if (this.file) {
        if (/\.(jpe?g|png|gif)$/i.test(this.file.name)) {
          reader.readAsDataURL(this.file);
        }
      }
    },
    handleFileUploadMobile() {
      console.log("2");
      this.file = this.$refs.fileMobile.files[0];
      let reader = new FileReader();
      reader.addEventListener(
        "load",
        function () {
          this.mobile = reader.result;
          console.log(this.mobile);
        }.bind(this),
        false
      );
      if (this.file) {
        if (/\.(jpe?g|png|gif)$/i.test(this.file.name)) {
          reader.readAsDataURL(this.file);
        }
      }
    },
    async BANNERS_add() {
      if (Helper.methods.isEmptyStr(this.name)) {
        Helper.methods.warning("Поле текст не должно быть пустым", "error");
        return;
      }
      if (Helper.methods.isEmptyStr(this.desctop)) {
        Helper.methods.warning("Нужно указать файл для DESCTOP", "error");
        return;
      }
      if (Helper.methods.isEmptyStr(this.mobile)) {
        Helper.methods.warning("Нужно указать файл для MOBILE", "error");
        return;
      }
      if (Helper.methods.isEmptyStr(this.link)) {
        Helper.methods.warning("Поле ссылка не должно быть пустым", "error");
        return;
      }
      let response = await api.methods.BANNERS_add({
        mobile: this.mobile,
        link: this.link,
        desctop: this.desctop,
        name: this.name,
        tag: this.tag,
      });
      this.mobile = "";
      this.link = "";
      this.desctop = "";
      this.name = "";
      this.tag = "";

      Helper.methods.warning(response, "ok");
      this.list();
    },
  },
  async mounted() {
    this.list();
  },
  async created() {},
  components: {
    Header,
  },
};
</script>